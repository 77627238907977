import Image from "next/image";

import React, {useEffect, useState} from "react";
import axios from "axios";
import Link from "next/link";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

function Work() {
  const [clients, setClients] = useState<any>([]);

  const fetchClients = async () => {
      try {
          const result = await axios.get(`${process.env.admin_url}/api/clients?populate=*`);
          setClients(result?.data?.data);
      } catch (e) {
          console.log(e);
      }
  }

  useEffect( () => { fetchClients(); }, []);

  return (
    <div>
      <div className="quote-background">
        <div className="stars"></div>
        <div className="stars"></div>
        <div className="stars"></div>
        <div className="stars"></div>
        <div className="stars"></div>
      </div>
      <div className="bg-dark-gradient bg-blend-screen flex flex-wrap rounded-xl text-center max-w-7xl mx-auto py-10 mb-[-10rem] md:mb-[-13.75rem]">
        <div className="sm:px-4 w-full z-10">
          <h1 className="md:text-header text-header-mbl font-sourcesans relative">
            <span className="text-secondary-500 after:content-[''] after:absolute after:w-24 md:after:w-32 after:h-1 md:after:h-2 after:top-[50px] md:after:top-[65px] after:left-[40%] md:after:left-[45%] after:border-4 md:after:border-4 after:border-secondary-500 after:rounded">
              <span className="text-white mr-4">Our</span>
              <span className="text-orange mr-2">Clients</span>
            </span>
          </h1>
          <p className="text-white md:text-body text-body-mbl mt-6 py-4 px-10 md:px-[16rem]">
          Virtual Gravity is a design and development company that specializes in building small and enterprise level software for
          </p>
        </div>
        <div className="flex flex-wrap justify-center px-4 mt-4 w-full">
          {
             (clients||[]).length > 0 && (
              <Swiper
                modules={[Autoplay, A11y]}
                spaceBetween={30}
                slidesPerView={clients.length < 4 ? clients.length : 4}
                loop={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true
                }}
                breakpoints={{
                  // when window width is >= 320px
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  // when window width is >= 480px
                  480: {
                    slidesPerView: 3,
                    spaceBetween: 20
                  },
                  // when window width is >= 640px
                  640: {
                    slidesPerView: clients.length < 4 ? clients.length : 4,
                    spaceBetween: 30
                  }
                }}
              >
              {
                clients.map((client: any, index: any) => (
                  <SwiperSlide key={index}>
                    <div className="max-w-[105px] md:max-w-full m-4 md:mx-16">
                      <Link
                        href={client.attributes.link}
                        passHref
                      >
                        <a target="_blank">
                          <Image 
                            src={process.env.admin_url + client?.attributes?.logo?.data?.attributes?.url} 
                            alt={client?.attributes?.name} 
                            height={55}
                            width={158}
                            className="cursor-pointer"
                            />
                        </a>
                      </Link>
                    </div>
                    </SwiperSlide>
                  ))
              }
              </Swiper>
             )
          }
        </div>
      </div>
    </div>
  );
}

export default Work;
